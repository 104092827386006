import roleServices from '../../../../services/role'
import VueDropzone from 'vue2-dropzone'
import quickLinkService from '../../../../services/notisphere/quick-link'
export default {
    data() {
        return {
            item: {
                LinkUrl: null,
                Icon: null,
                organization_id: []
            },
            dataPhoto: '',
            formType: 'INSERT',
            organizations: [],
            fileInfo: [],
            AdministerlinkId: 0,
            labelName: {
                LinkUrl: 'URL',
                Icon: 'Icon',
                organization_id: 'Organizations'
            },
            dropzoneOptions: {
                url: '#',
                acceptedFiles: '.xlsx',
                maxFiles: 1,
                maxFilesize: 20,
                previewsContainer: false,
                autoProcessQueue: false
            },
            imgPath: '',
            selectedOrgText: []

        }
    },
    components: {
        VueDropzone
    },
    computed: {
        form() {
            if (this.formType == 'INSERT') {
                return {
                    title: 'New Quick Link',
                    isDisabled: false
                }
            }
            if (this.formType == 'UPDATE') {
                return {
                    title: 'Update Quick Link',
                    isDisabled: true
                }
            }
            if (this.formType == 'VIEW') {
                return {
                    title: 'View QuickLink Details',
                    isDisabled: true
                }
            }
        }

    },

    methods: {
        forceRerenderInsert() {
            this.formType = 'INSERT'
            this.fetchOrgMeta()
            this.$refs.modal.show()
        },
        forceRerenderUpdate(id) {
            this.formType = 'UPDATE'
            this.getquicklinksbyId(id)
            this.AdministerlinkId = id
            this.fetchOrgMeta()
            this.$refs.modal.show()
        },
        forceRerenderView(id) {
            this.formType = 'VIEW'
            this.getquicklinksbyId(id)
            this.fetchOrgMeta(id)
            this.AdministerlinkId = id
            this.$refs.modal.show()
        },
        getquicklinksbyId(id) {
            quickLinkService.getquicklinksbyId(id).then(resp => {
                if (!resp.error) {
                    this.item.LinkUrl = resp.data.d.linkUrl
                    var selectedorg = []
                    if (resp.data.d.selectedAccount != null) {
                        for (let i = 0; i < resp.data.d.selectedAccount.length; i++) {
                            selectedorg.push(
                                resp.data.d.selectedAccount[i].text,
                                resp.data.d.selectedAccount[i].value
                            )

                            this.selectedOrgText.push(resp.data.d.selectedAccount[i].text)
                        }
                    }
                    this.imgPath = resp.data.d.path
                    this.item.organization_id = selectedorg
                    this.fileInfo.name = resp.data.d.fileName + resp.data.d.extension
                    this.fileInfo.type = resp.data.d.mimeType
                    this.fileInfo.size = resp.data.d.fileSize
                    this.fileInfo.extension = resp.data.d.extension
                    this.$nextTick(() => {
                        this.$refs.modal.show()
                    })
                }
            })
        },
        removeDocument(index) {
            this.filesUpload.splice(index, 1)
            this.thumbnailIndex = this.filesUpload.findIndex(x => x.is_thumbnail === true)

        },
        handleFilesUpload() {
            let extAllow = ['image/png', 'image/jpeg', 'image/jpg']
            this.fileInfo = this.$refs.fileimg.files[0]
            var ext = this.fileInfo.name.split('.')
            if (['jpg'].includes(ext[ext.length - 1]) || ['png'].includes(ext[ext.length - 1])) {

                if (this.fileInfo.size == 0) {
                    this._showToast('Documented failed to upload. Please try again.', { variant: 'danger' })
                }
                else {
                    if (this.fileInfo.size < 20000) {

                        if (!extAllow.includes(this.fileInfo.type)) {
                            return
                        }

                        let reader = new FileReader()

                        reader.readAsDataURL(this.fileInfo)
                        reader.onload = e => {
                            this.dataPhoto = e.target.result
                            if (this.dataPhoto) {
                                this.item.Icon = this.fileInfo
                            }
                        }

                    } else {
                        this.fileInfo = []
                        this._showToast('File size should be smaller than 20KB', { variant: 'danger' })
                    }
                }
            } else {
                this.fileInfo = []
                this._showToast('The file extension should be .jpg or .png.', { variant: 'danger' })
            }
        },

        onPaste(e) {
            var regex = new RegExp('(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*(\\?[;&a-z\\d%_.~+=-@]*)?(\\#[-a-z\\d_@]*)?$', 'i')
            var sd = e.clipboardData.getData('text')

            if (!regex.test(sd)) {
                e.preventDefault()
                return false
            }
        },
        dragover(event) {
            event.preventDefault()
            if (!event.currentTarget.classList.contains('bg-dz-hover')) {
                event.currentTarget.classList.add('bg-dz-hover')
            }
        },
        drop(event) {
            event.preventDefault()
            this.$refs.files.files = event.dataTransfer.files
            this.handleFilesUpload()
            event.currentTarget.classList.remove('bg-dz-hover')
        },
        dragleave(event) {
            event.currentTarget.classList.remove('bg-dz-hover')
        },
        async save() {
            var isValid = await this.$refs['observer'].validate()
            var isFile = this.fileValidate()
            if (isValid && isFile && this.formType == 'INSERT') {
                this.createQuickLink()
            }
            if (isValid && isFile && this.formType == 'UPDATE') {
                this.createQuickLink()
            }
        },
        fileValidate() {
            if (this.formType == 'UPDATE') {
                return true
            }
            else {
                if (this.fileInfo.length == 0) {
                    this._showToast('Please select file', { variant: 'danger' })
                    return false
                } else {
                    return true
                }
            }

        },

        createQuickLink() {
            let formData = new FormData()
            formData.append('LinkUrl', this.item.LinkUrl)
            formData.append('Icon', this.item.Icon)
            formData.append('AdministerlinkId', this.AdministerlinkId)
            var selectedorg = []
            if (this.formType == 'UPDATE') {
                if (this.item.organization_id != null) {
                    for (let i = 0; i < this.item.organization_id.length; i++) {
                        var type = typeof this.item.organization_id[i]
                        if (type == 'number') {
                            selectedorg.push(
                                this.item.organization_id[i]
                            )
                        }
                    }
                }
                formData.append('OrganizationId', selectedorg)

            }
            else {
                formData.append('OrganizationId', this.item.organization_id)
            }
            quickLinkService.create_QuickLink(formData).then(resp => {
                if (!resp.error) {
                    if (this.formType == 'INSERT') {
                        this.$emit('onCompleted', 'INSERT')
                        this.$refs.modal.hide()
                    }
                    if (this.formType == 'UPDATE') {
                        this.$emit('onCompleted', 'UPDATE')
                        this.$refs.modal.hide()
                    }
                }
            })
        },

        getRoleMeta(organization_id) {
            roleServices.getMeta(organization_id).then(resp => {
                if (!resp.error) {
                    if (this.formType == 'INSERT') {
                        this.roleMetas = resp.data.d.filter(x => x.allowed_creation == true)
                    }
                    if (this.formType == 'UPDATE') {
                        this.roleMetas = resp.data.d
                    }
                }
            })
        },
        fetchOrgMeta() {
            quickLinkService.findMeta().then(resp => {
                if (!resp.error) {
                    this.organizations = resp.data.d
                }
            })
        },
    }
}