import { TYPICAL_STATUS} from '../../../misc/commons'
import quickLinkService from '../../../services/notisphere/quick-link'
import QuickLinkModal from './QuickLinkModal'
export default {
    components: {
        QuickLinkModal
    },
    data(){
        return{
            total: 0,
            items: [],
            searchQuery: {},
            search: {
                url: null,
                status: null,
            },
            status: null,
            locationPreProcessor_status: TYPICAL_STATUS,
            providerOrgId: null,
            header: [
                {
                    label: 'URL',
                    name: 'linkUrl',
                    row_text_alignment: 'text-left',
                    sort: true,
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Icon',
                    name: 'avatar',
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'No. of accounts',
                    name: 'organizationCount',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left'
                },
                {
                    label: '',
                    name: 'action',
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center'
                }
            ],
            quickLinkModalKey: 0,
            linkId: null,
            listIDN: [],
            listAccounts: [],

        }
    },
    methods: {
        fetchData() {
            quickLinkService.findQuickLink({
                ...this.searchQuery,
                sortField: this.searchQuery.sort[0] ? this.searchQuery.sort[0].name : null,
                sortDirection: this.searchQuery.sort[0] ? this.searchQuery.sort[0].order : null,
                ...this.search
            }).then(resp=>{
                if(!resp.error) {
                    this.items = resp.data.d.c
                    this.total = resp.data.d.t
                }
            })
        },
        view(item){
            this.quickLinkModalKey += 1
            this.$nextTick(()=>{
                this.$refs.quickLinkModal.forceRerenderView(item.administerlinkId)
            })
        },
        editQuickLink(item, e) {
            e.stopPropagation()
            this.quickLinkModalKey += 1
            this.$nextTick(()=>{
                this.$refs.quickLinkModal.forceRerenderUpdate(item)
            })
        },
        deleteQuickLink(item, e){
            quickLinkService.deleteQuickLink(item.administerlinkId).then(resp => {
                if (!resp.error) {
                    this._showToast('Quick link deleted successfully!')
                    this.fetchData()
                }
            })
            if (e) {
                e.stopPropagation()
            }
        },
        addMoreClick() {
            this.quickLinkModalKey += 1
            this.$nextTick(() => {
                this.$refs.quickLinkModal.forceRerenderInsert()
            })
        },
        onCompleted(type) {
            if (type == 'UPDATE') {
                this._showToast('Updated successfully!')
            }
            if (type == 'INSERT') {
                this._showToast('Added successfully!')
            }
            this.fetchData()
        },
        updatequicklink(payload, e = null) {
            this.quickLinkModalKey += 1
            this.$nextTick(() => {
                this.$refs.quickLinkModal.forceRerenderUpdate(payload.administerlinkId)
            })
            if (e) {
                e.stopPropagation()
            }
        },
    }
}